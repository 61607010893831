(function ($, app, _) {
  'use strict';
  app.m69 = function () {

    if( $('section.m69').length == 0 ) return;

    let options = {
      valueNames: [ 
        { data: ['id'] },
        { data: ['search'] },
        { data: ['year'] },
        { data: ['topic'] },
        { data: ['format'] },
        { data: ['field'] }
      ]
    };

    let options_filter        = { itemSelectText: '', shouldSort: false, searchFields: ['label'] }

    let material       = new List('m69_material', options);
    let choices_year   = new Choices($('select[name="ois-filter-year"]')[0],   options_filter)
    let choices_topic  = new Choices($('select[name="ois-filter-topic"]')[0],  options_filter)
    let choices_format = new Choices($('select[name="ois-filter-format"]')[0], options_filter)
    let choices_field  = new Choices($('select[name="ois-filter-field"]')[0],  options_filter)

    filterMaterial();
    setTimeout( updateUI, 10 );
    $('section.m69 .sidebar').removeClass('loading')

    $('input[name="ois-search"]').on('keyup', function() {
      filterMaterial();
      setTimeout( updateUI, 10 );
    });

    $('select[name^="ois-filter-"]').on('change', function() {
      filterMaterial();
      setTimeout( updateUI, 10 );
    });

    $('.m69 .reset').on('click', function() {
      // reset fields
      $('input[name="ois-search"]').val('')
      choices_year.setChoiceByValue('') 
      choices_topic.setChoiceByValue('') 
      choices_format.setChoiceByValue('') 
      choices_field.setChoiceByValue('') 
      
      // reset filter
      material.filter();
      material.search();
      
      // update counter
      setTimeout( updateUI, 10 );
    });

    $('.m69 .toggleAll').on('click', function() {
      $('.material .list').addClass('showAll');
    });

    $('.m69 .sidebar_toggle').on('click', function() {
      $('.sidebar').toggleClass('showForm');
    });

    function filterMaterial() {
      let val_search = $('input[name="ois-search"]').val().toLowerCase()
      let val_year   = $('select[name="ois-filter-year"]').val()
      let val_topic  = $('select[name="ois-filter-topic"]').val()
      let val_format = $('select[name="ois-filter-format"]').val()
      let val_field  = $('select[name="ois-filter-field"]').val()
      
      material.filter(function(item) { 
        if( item.values().search != '*' && item.values().search.toLowerCase().indexOf(val_search) == -1 ) return false
        if( item.values().year   != '*' && item.values().year.indexOf(val_year)                   == -1 ) return false
        if( item.values().topic  != '*' && item.values().topic.indexOf(val_topic)                 == -1 ) return false
        if( item.values().format != '*' && item.values().format.indexOf(val_format)               == -1 ) return false
        if( item.values().field  != '*' && item.values().field.indexOf(val_field)                 == -1 ) return false
        
        return true
      }); 
    }

    function updateUI() {
      // reset chapter visibility to fix calculations
      $('.project__chapter').show()

      let dummies          = $('.project__chapter').length
      let participateItems = $('.project.take-part').length
      let visibleItems     = material.visibleItems.length - dummies
      let projectItems     = material.items.length - dummies

      let html = ( visibleItems == projectItems ) ? visibleItems + ' items' : visibleItems + ' of ' + projectItems + ' items'

      if( document.documentElement.lang == 'de-DE' ) {
        html = ( visibleItems == projectItems ) ? visibleItems + ' Einträge' : visibleItems + ' von ' + projectItems + ' Einträge'
      } 

      // update project count
      $('.sidebar .count').html( html )
      
      // hide toggle, if projects are less than 4
      $('.toggleAll').toggle( material.visibleItems.length > 4 )
      
      // show / hide headlines dynamically
      $('.project__chapter.take-part').toggle( participateItems > 0 )
      $('.project__chapter.cant-take-part').toggle( (visibleItems - participateItems) > 0 )

      // pick correct label for headline
      $('.project__chapter span.h_more').toggle( participateItems > 0 )
      $('.project__chapter span.h_material').toggle( participateItems <= 0 )

      // show / hide "no material found" message
      $('.no-material-found').toggle( visibleItems <= 0 )
    }
  };
})(jQuery, app, _);
