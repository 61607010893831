(function ($, app, _) {
  'use strict';
  app.showAnker = function () {
    function hasUrlParameter(name) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.has(name);
    }

    // Check if 'show-anker' parameter is present
    console.log( hasUrlParameter('show-anker') )
    if (hasUrlParameter('show-anker')) {
        // Find all elements with data-scroll-title attribute
        $('[data-scroll-title]').each(function() {
            const title = $(this).attr('data-scroll-title'); // Get the attribute value

            // Create label element
            const label = $('<div class="scroll-label"></div>')
                .text('#' + title)
                .css({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    background: 'yellow',
                    padding: '5px 10px',
                    border: '1px solid black',
                    borderRadius: '5px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    zIndex: 1000
                });

            // Append label to body
            $(this).css({position: 'relative'})
            $(this).append(label);
        });
    }
  };
})(jQuery, app, _);
